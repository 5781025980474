// extracted by mini-css-extract-plugin
export var accent = "winter-contest-form-module--accent--d2684";
export var answerBox = "winter-contest-form-module--answer-box--dac2b";
export var backgroundAnimation = "winter-contest-form-module--backgroundAnimation--04fb2";
export var bold = "winter-contest-form-module--bold--c042d";
export var button = "winter-contest-form-module--button--3be4a";
export var calendarIcon = "winter-contest-form-module--calendar-icon--86575";
export var checkbox = "winter-contest-form-module--checkbox--a19a6";
export var checkboxLabel = "winter-contest-form-module--checkbox-label--33aa1";
export var checkboxes = "winter-contest-form-module--checkboxes--10340";
export var checkmark = "winter-contest-form-module--checkmark--5c5c4";
export var container = "winter-contest-form-module--container--7731b";
export var counter = "winter-contest-form-module--counter--3cc3a";
export var description = "winter-contest-form-module--description--ba66f";
export var error = "winter-contest-form-module--error--983b4";
export var errorBlink = "winter-contest-form-module--error-blink--26f56";
export var fields = "winter-contest-form-module--fields--7b014";
export var fieldsWrapper = "winter-contest-form-module--fields-wrapper--7f70a";
export var fileContainer = "winter-contest-form-module--file-container--71ad4";
export var fileInput = "winter-contest-form-module--file-input--5f85e";
export var fileSection = "winter-contest-form-module--file-section--9ad55";
export var formField = "winter-contest-form-module--form-field--40bc8";
export var input = "winter-contest-form-module--input--52da3";
export var loading = "winter-contest-form-module--loading--39758";
export var phoneInput = "winter-contest-form-module--phone-input--aca98";
export var position = "winter-contest-form-module--position--fe19b";
export var receiptField = "winter-contest-form-module--receipt-field--c0c14";
export var select = "winter-contest-form-module--select--a7286";
export var selectBox = "winter-contest-form-module--select-box--8a091";
export var selectIcon = "winter-contest-form-module--select-icon--96ed2";
export var subtitle = "winter-contest-form-module--subtitle--8a12d";
export var text = "winter-contest-form-module--text--599c2";
export var textSmall = "winter-contest-form-module--text-small--527ef";
export var textarea = "winter-contest-form-module--textarea--fafca";
export var title = "winter-contest-form-module--title--f6eae";