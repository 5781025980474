import React, { useRef, useState } from 'react';
import { Form, Formik, Field, ErrorMessage, FormikConfig, FormikProps } from 'formik';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';

import {
    container,
    textarea,
    input,
    counter,
    fields,
    checkboxes,
    checkbox,
    checkboxLabel,
    checkmark,
    button,
    loading,
    fileContainer,
    fileInput,
    phoneInput,
    title,
    bold,
    accent,
    subtitle,
    description,
    answerBox,
    error as errorClass,
    fileSection,
    text,
    textSmall,
    calendarIcon,
    formField,
    selectBox,
    selectIcon,
    select,
    fieldsWrapper,
    receiptField,
} from './winter-contest-form.module.scss';

import ChevronIcon from '../../assets/images/svg/angle.svg';
import Calendar from '../../assets/images/svg/calendar.svg';

import { IError } from '../../aes/utils/get-form-errors';
import pagesContext from '../../config/pages-context';
import staticFiles from '../../config/static-files';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { winterContestFormMutation } from '../../api-ssr/winter-contest';
import {
    getValidationSchema,
    initialValues,
    IWinterContestValues,
    WINTER_CONTEST_MAX_ANSWER_LENGTH,
    WINTER_CONTEST_MAX_FILE_SIZE,
} from '../../formik/winter-contest-form.config';

import FormCheckbox from '../atoms/form-checkbox';
import Button from '../atoms/button';
import FileInput from '../atoms/file-input';
import FormField from '../atoms/form-field';
import HandleFormikChange from '../hoc/handle-formik-change';
import FormInput from '../atoms/form-input';

interface IWinterContestFormProps {
    className?: string;
}

const WinterContestForm: React.FC<IWinterContestFormProps> = ({ className = '' }) => {
    const { t, language } = useI18next();
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync } = useMutation(winterContestFormMutation);
    const formRef = useRef<FormikProps<IWinterContestValues>>(null);
    const [dateType, setDateType] = useState('text');
    const [globalErrors, setGlobalErrors] = useState<any[]>();

    const handleSubmit: FormikConfig<IWinterContestValues>['onSubmit'] = async (values) => {
        setIsLoading(true);
        try {
            await mutateAsync(values);
            const resultsUrl = getPathFromSlug(pagesContext.winterContestThankYou.slug, language);
            navigate(resultsUrl);
        } catch (err: any) {
            const error = { ...err }.response;
            if (
                'status' in error &&
                typeof error.status === 'number' &&
                error.data &&
                typeof error.data === 'object' &&
                'messages' in error.data &&
                Array.isArray(error.data.messages)
            ) {
                const errors: IError[] = [];
                error.data.messages.forEach((msg: IError) => {
                    errors.push(msg);
                });
                setGlobalErrors(errors);
            } else {
                alert(error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormikChange = (values: IWinterContestValues) => {
        if (values.phone.toString().length > 9) {
            formRef.current?.setFieldValue('phone', values.phone.toString().slice(0, 9));
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            innerRef={formRef}
            validationSchema={getValidationSchema(t)}
        >
            {({ values, errors, touched }) => (
                <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                    <HandleFormikChange onChange={handleFormikChange} />
                    <p className={`${title} ${bold}`}>{t('winter.contest.form.title.1')}</p>
                    <p className={title}>{t('winter.contest.form.title.2')}</p>
                    <p className={`${title} ${bold} ${accent}`}>
                        {t('winter.contest.form.title.3')}
                    </p>
                    <p className={subtitle}>{t('winter.contest.form.subtitle')}</p>
                    <p className={description}>{t('winter.contest.form.answer')}</p>

                    <div className={fieldsWrapper}>
                        <div className={answerBox}>
                            <Field
                                as="textarea"
                                type="textarea"
                                rows={5}
                                className={`${textarea} ${input}`}
                                name="answer"
                                placeholder={t('winter.contest.form.answer.placeholder')}
                                maxLength={WINTER_CONTEST_MAX_ANSWER_LENGTH}
                            />
                            <p className={counter}>
                                {values.answer.length}/{WINTER_CONTEST_MAX_ANSWER_LENGTH}
                            </p>
                            <ErrorMessage name="answer" className={errorClass} component="p" />
                        </div>
                        <div className={fileSection}>
                            <p className={text}>{t('winter.contest.form.files')}</p>
                            <p className={textSmall}>{t('winter.contest.form.files.hint')}</p>
                            <FormField id={'file-field'} className={fileContainer}>
                                <>
                                    <FileInput
                                        maxFileSize={WINTER_CONTEST_MAX_FILE_SIZE}
                                        fileTypes={[
                                            'application/pdf',
                                            'image/jpg',
                                            'image/jpeg',
                                            'image/png',
                                            'video/x-msvideo',
                                            'video/mov',
                                            'video/mp4',
                                        ]}
                                        className={fileInput}
                                        name={'files'}
                                        placeholder={t('winter.contest.form.files.placeholder')}
                                        type={'short'}
                                    />
                                    <ErrorMessage
                                        name="files"
                                        className={errorClass}
                                        component="p"
                                    />
                                </>
                            </FormField>
                        </div>
                        <div className={fields}>
                            <div>
                                <Field
                                    className={input}
                                    name="firstname"
                                    placeholder={t('winter.contest.form.firstname.placeholder')}
                                />
                                <ErrorMessage
                                    name="firstname"
                                    className={errorClass}
                                    component="p"
                                />
                            </div>
                            <div>
                                <Field
                                    className={input}
                                    name="lastname"
                                    placeholder={t('winter.contest.form.lastname.placeholder')}
                                />
                                <ErrorMessage
                                    name="lastname"
                                    className={errorClass}
                                    component="p"
                                />
                            </div>
                            <FormField
                                id={'birthday'}
                                hintText={
                                    errors.birthday && touched.birthday ? errors.birthday : null
                                }
                                errorStyle={!!(errors.birthday && touched.birthday)}
                                className={formField}
                            >
                                <>
                                    <FormInput
                                        id={'birthday'}
                                        name={'birthday'}
                                        placeholder={t('winter.contest.form.birthday.placeholder')}
                                        errorStyle={!!(errors.birthday && touched.birthday)}
                                        className={input}
                                        onClick={() => {
                                            setDateType('date');
                                        }}
                                        type={dateType}
                                    />
                                    <Calendar className={calendarIcon} />
                                </>
                            </FormField>
                            <div>
                                <Field
                                    type="email"
                                    className={input}
                                    name="email"
                                    placeholder={t('winter.contest.form.email.placeholder')}
                                />
                                <ErrorMessage name="email" className={errorClass} component="p" />
                            </div>
                            <div className={phoneInput}>
                                <Field
                                    type="phone"
                                    className={`${input}`}
                                    name="phone"
                                    placeholder={t('winter.contest.form.phone.placeholder')}
                                />
                                <ErrorMessage name="phone" className={errorClass} component="p" />
                            </div>
                            <div>
                                <div className={selectBox}>
                                    <Field as="select" className={`${input} ${select}`} name="size">
                                        <option value="" disabled={true}>
                                            {t('winter.contest.form.size.placeholder')}
                                        </option>
                                        <option value="xs">XS</option>
                                        <option value="s">S</option>
                                        <option value="m">M</option>
                                        <option value="l">L</option>
                                        <option value="xl">XL</option>
                                        <option value="xxl">XXL</option>
                                    </Field>
                                    <ChevronIcon className={selectIcon} />
                                </div>
                                <ErrorMessage name="size" className={errorClass} component="p" />
                            </div>
                            <div>
                                <Field
                                    className={input}
                                    name="receiptNumber"
                                    placeholder={t('winter.contest.form.receipt.placeholder')}
                                />
                                <ErrorMessage
                                    name="receiptNumber"
                                    className={errorClass}
                                    component="p"
                                />
                                {globalErrors &&
                                    globalErrors.map((error, i) => (
                                        <p className={errorClass} key={`error-${i}`}>
                                            {error.content}
                                        </p>
                                    ))}
                            </div>
                            <FormField id={'receiptFile-field'} className={receiptField}>
                                <>
                                    <FileInput
                                        maxFileSize={WINTER_CONTEST_MAX_FILE_SIZE}
                                        fileTypes={[
                                            'application/pdf',
                                            'image/jpg',
                                            'image/jpeg',
                                            'image/png',
                                            'video/x-msvideo',
                                            'video/mov',
                                            'video/mp4',
                                        ]}
                                        className={fileInput}
                                        name={'receiptFiles'}
                                        placeholder={t(
                                            'winter.contest.form.receiptImage.placeholder'
                                        )}
                                        type={'receipt'}
                                    />
                                    <ErrorMessage
                                        name="receiptFiles"
                                        className={errorClass}
                                        component="p"
                                    />
                                </>
                            </FormField>
                        </div>
                        <div className={checkboxes}>
                            <div>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="rules1"
                                    text={t('winter.contest.form.rules1')}
                                    textLink={t('winter.contest.form.rules1.textLink')}
                                    link={staticFiles.winterContestRegulations}
                                    textContinued={t('winter.contest.form.rules1.textContinued')}
                                />
                                <ErrorMessage name="rules1" className={errorClass} component="p" />
                            </div>
                            <div>
                                <FormCheckbox
                                    textClass={checkboxLabel}
                                    className={checkbox}
                                    checkmarkClass={checkmark}
                                    name="rules2"
                                    text={t('winter.contest.form.rules2')}
                                />
                                <ErrorMessage name="rules2" className={errorClass} component="p" />
                            </div>
                        </div>
                        <Button isLoading={isLoading} type="submit" className={button}>
                            {t('winter.contest.form.submit')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default WinterContestForm;
