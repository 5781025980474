import React, { ReactElement } from 'react';
import { label, labelFlex } from './form-label.module.scss';
import Tooltip from '../atoms/tooltip';

interface IFormLabel {
    className?: string;
    labelText: string | ReactElement;
    htmlFor?: string;
    tooltip?: boolean;
    tooltipText?: string;
}

const FormLabel: React.FC<IFormLabel> = ({ className = '', labelText, htmlFor, tooltip, tooltipText }) => {
    const Tag = getLabelTag(htmlFor);

    return (
        <Tag className={`${label} ${className} ${tooltip ? labelFlex : ''}`} htmlFor={htmlFor}>
            {labelText}
            {tooltip && tooltipText && <Tooltip tooltipText={tooltipText} />}
        </Tag>
    );
};

const getLabelTag = (htmlFor: string | undefined) => {
    if (htmlFor) return 'label';
    return 'span';
};
export default FormLabel;
