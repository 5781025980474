import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { object, string } from 'yup';

export interface IWinterContestValues {
    answer: string;
    files: { content: string; mimeType: string; name: string }[] | null;
    firstname: string;
    lastname: string;
    birthday: string;
    email: string;
    phone: string;
    phonePrefix: string;
    size: string;
    receiptNumber: string;
    receiptFiles: { content: string; mimeType: string; name: string }[] | null;
    rules1: boolean;
    rules2: boolean;
}

// Tylko cyfry od 0 do 9, równo 9 cyfr
const phoneRegExp = /^[0-9]{9}$/;

export const initialValues: IWinterContestValues = {
    answer: '',
    files: null,
    firstname: '',
    lastname: '',
    birthday: '',
    email: '',
    phone: '',
    phonePrefix: '+48',
    size: '',
    receiptNumber: '',
    receiptFiles: null,
    rules1: false,
    rules2: false,
};

export const WINTER_CONTEST_MAX_ANSWER_LENGTH = 500;
export const WINTER_CONTEST_MAX_FILE_SIZE = 26214400;

export function getValidationSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape(
        {
            answer: Yup.string()
                .max(WINTER_CONTEST_MAX_ANSWER_LENGTH)
                .required(t('form.required.error')),
            files: Yup.array()
                .of(object({ content: string(), mimeType: string(), name: string() }))
                .nullable(),
            firstname: Yup.string().required(t('form.required.error')),
            lastname: Yup.string().required(t('form.required.error')),
            birthday: Yup.date()
                .nullable()
                .test('birthday', t('form.age.error'), function (value, ctx) {
                    if (value) {
                        const date = new Date(value);
                        const now = new Date();
                        const OF_AGE = now.getFullYear() - date.getFullYear() > 18;
                        const YEARS_VALID = now.getFullYear() - date.getFullYear() === 18;
                        const MONTHS_VALID = now.getMonth() - date.getMonth() >= 0;
                        const DAYS_VALID = now.getDate() - date.getDate() >= 0;

                        const valid = OF_AGE || (YEARS_VALID && MONTHS_VALID && DAYS_VALID);
                        return !valid ? ctx.createError() : valid;
                    }
                    return ctx.createError();
                })
                .required(t('form.required.error')),
            email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
            phonePrefix: Yup.string().required(t('form.required.error')),
            phone: Yup.string()
                .matches(phoneRegExp, t('form.phone.error'))
                .required(t('form.required.error')),
            size: Yup.string().required(t('form.required.error')),
            receiptNumber: Yup.string().required(t('form.required.error')),
            receiptFiles: Yup.array()
                .of(object({ content: string(), mimeType: string(), name: string() }))
                .nullable()
                .required(t('form.required.error')),
            rules1: Yup.boolean().oneOf([true], t('form.required.error')),
            rules2: Yup.boolean().oneOf([true], t('form.required.error')),
        },
        []
    );
}
