import React, { ReactElement } from 'react';

import { wrapper } from './form-field.module.scss';

import FormLabel from './form-label';
import FormHint from './form-hint';

interface IFormField {
    className?: string;
    labelText?: string | ReactElement;
    elementId?: string;
    id: string;
    hintText?: string | null | ReactElement;
    tooltip?: boolean;
    tooltipText?: string;
    children?: ReactElement;
    errorStyle?: boolean;
}

const FormField: React.FC<IFormField> = ({
    className = '',
    labelText = '',
    elementId,
    id,
    hintText,
    tooltip,
    tooltipText,
    children,
    errorStyle = false,
}) => {
    const showHint = (!tooltip || (tooltip && errorStyle)) && hintText;

    return (
        <div id={elementId} className={`${wrapper} ${className}`}>
            {labelText && (
                <FormLabel
                    tooltip={tooltip}
                    tooltipText={tooltipText}
                    labelText={labelText}
                    htmlFor={id}
                />
            )}
            {children}
            {}
            {showHint && (
                <FormHint errorStyle={errorStyle} hintText={hintText} className={'hint'} />
            )}
        </div>
    );
};

export default FormField;
