import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

import { container, prize, form } from './main.module.scss';

import { IPage } from '../../../models/page.model';

import WinterLayout from '../../../layouts/winter-layout';
import WinterContestPrize from '../../../components/organisms/winter-contest-prize';
import WinterContestForm from '../../../components/organisms/winter-contest-form';

interface IWinterContestPage {
    readonly data: {
        page: IPage | null;
    };
}

const Main: React.FC<IWinterContestPage> = ({ data }) => {
    const { page } = data;

    const containerRef = useRef<HTMLDivElement>(null);

    const bannerOnClick = () => {
        containerRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    };

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <WinterLayout
                className={container}
                pageType={'main'}
                bannerOnClick={bannerOnClick}
                page={page}
            >
                <div ref={containerRef} className={prize}>
                    <WinterContestPrize />
                </div>
                <WinterContestForm className={form} />
            </WinterLayout>
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Main;
