import React, { ReactElement } from 'react';

import { hint, hintError } from './form-hint.module.scss';

interface IFormHint {
    hintText: string | ReactElement;
    errorStyle?: boolean;
    className?: string;
}

const FormHint: React.FC<IFormHint> = ({ className = '', hintText, errorStyle = false }) => {
    return (
        <span className={`${hint} ${className} ${errorStyle ? hintError : ''}`}>{hintText}</span>
    );
};

export default FormHint;
