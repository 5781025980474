import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IWinterContestValues } from '../formik/winter-contest-form.config';
import { IWinterContestWinnerValues } from '../formik/winter-contest-form-winner.config';

export const winterContestFormMutation = (data: IWinterContestValues) => {
    return axios.post(prependApiUrl('/zima-goraca/create'), {
        ...data,
    });
};

export const postWinterContestWinner = (createForm: IWinterContestWinnerValues) => {
    return axios.post(prependApiUrl('/zima-goraca/addresses'), {
        ...createForm,
    });
};

export const getWinterContestCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/zima-goraca/check/${token}`), {});
};
