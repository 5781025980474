import React from 'react';
import { Field } from 'formik';

import { input, inputError } from './form-input.module.scss';

interface IFormInput {
    id: string;
    name: string;
    className?: string;
    type?: string;
    placeholder?: string;
    errorStyle?: boolean;
    as?: string;
    maxlength?: number;
    onClick?: () => void;
}

const FormInput: React.FC<IFormInput> = ({
    type,
    id,
    name,
    placeholder,
    errorStyle = false,
    as = 'input',
    className = '',
    maxlength = '',
    onClick,
}) => {
    return (
        <Field
            className={`${input} ${className} ${errorStyle ? inputError : ''}`}
            type={type}
            id={id}
            name={name}
            as={as}
            placeholder={placeholder}
            maxLength={maxlength}
            onClick={onClick}
        />
    );
};

export default FormInput;
