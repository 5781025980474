import React from 'react';

import {tooltip, icon, content} from './tooltip.module.scss';
import Icon from '../../assets/images/svg/tooltip.svg';

interface ITooltip {
    tooltipText: string;
}

const Tooltip: React.FC<ITooltip> = ({tooltipText}) => {

    return (
        <div className={tooltip}>
            <Icon className={icon} />
            <p className={content}>{tooltipText}</p>
        </div>
    )
}

export default Tooltip;